import 'normalize.css'
import 'styles/index.scss'

var spotlight = document.querySelector('.spotlight');
var isUserMotion = false;

function move(e) {
  if (e.clientX || e.touches) {
    isUserMotion = true;
    spotlight.style.setProperty('--x', (e.clientX || e.touches[0].clientX)+'px');
    spotlight.style.setProperty('--y', (e.clientY || e.touches[0].clientY)+'px');
  }
}

if (spotlight) {
  if (window.PointerEvent) {
    spotlight.addEventListener('pointermove', move);
  } else {
    spotlight.addEventListener('touchmove', move);
    spotlight.addEventListener('mousemove', move);
  }
}

const setHeight = () => {
  const nav = document.querySelector('nav')
  nav.style.height = `${window.visualViewport.height}px`
}

setHeight()
window.addEventListener('resize', setHeight)


// URL Adjuster
const urlParams = new URLSearchParams(window.location.search)

const opacity = urlParams.get('opacity')

if (opacity !== null) {
  document.documentElement.style.setProperty('--opacity', `${opacity}`)
}

//Play when first loaded, then let user control
// (() => {
//   var startTime = Date.now();
//   autorun();
//   function autorun() {
//     var now = Date.now();
//     if (!isUserMotion && now <= startTime + 3000) {
//       console.log(now - startTime);
//       spotlight.style.setProperty('--x', ((now - startTime) / 6000 * 100) + '%');
//       spotlight.style.setProperty('--y', ((now - startTime) / 6000 * 100) + '%');
//       requestAnimationFrame(autorun);
//     }
//   }
// })();
